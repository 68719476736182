import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { storage } from "firebase_config";
import asyncLib from 'async';
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import logo from "images/gp_logo.png";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import signature from "images/signature.jpg";
import pass from "images/pass.png";
import fail from "images/fail.png";
import moment from "moment";
import * as actions from "actions/itemActions";
import { register } from "fonts/font";
import { useSnackbar } from "notistack";
import "fonts/font"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
}));

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 16,
    border: 1,
    borderColor: "#1F497D",
    borderStyle: "solid",
  },
  viewBoxOuter: {
    margin: 16,
    position: "absolute",
  },
  viewBox: {
    border: 2,
    borderColor: "#1F497D",
    padding: 2,
    borderStyle: "solid",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  header: {
    height: 100,
    marginTop: 6,
    marginBottom: 25,
  },
  line: {
    height: 2,
    position: "fixed",
    top: 66,
    borderBottom: 2,
    borderColor: "#1F497D",
    borderStyle: "double",
    marginRight: 20,
    marginLeft: 20,
  },
  logo: {
    marginLeft: 35,
    width: 150,
  },
  contact: {
    color: "#1F497D",
    flexDirection: "column",
    fontSize: 12,
    position: "fixed",
    top: -98,
    left: 435,
    textDecoration: "none",
  },
  titleRow: {
    border: 0.75,
    marginRight: 20,
    marginLeft: 20,
    marginTop: -35,
    marginBottom: 30,
    fontWeight: "bold",
    borderRadius: 3,
    maxHeight: 22,
  },
  title: {
    postiton: "absolute",
    width: 225,
    top: -11.5,
    left: 145,
    textAlign: "center",
    fontSize: 16,
    fontFamily: "Merriweather",
    color: "#1F497D",
    backgroundColor: "white",
  },
  issueDate: {
    postiton: "absolute",
    width: 190,
    top: -16,
    right: -374,
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Merriweather",
    //color: '#1F497D',
    // backgroundColor: 'white',
    lineHeight: 0,
  },
  packageNumber: {
    postiton: "absolute",
    width: 190,
    top: -16,
    right: 24,
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Merriweather",
    //color: '#1F497D',
    // backgroundColor: 'white',
    lineHeight: 0,
  },
  sectionContainer: {
    paddingBottom: 30,
  },
  section: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: -10,
    //marginBottom: 60,
    border: 0.75,
    borderRadius: 3,
    minHeight: 16,
    fontFamily: "Domine",
    flexDirection: "column",
  },
  sectionTitle: {
    position: "absolute",
    top: -8,
    left: 16,
    fontSize: 12,
    backgroundColor: "white",
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
    color: "#1F497D",
  },
  sectionBody: {
    marginTop: 10,
    marginBottom: 7,
    marginLeft: 20,
    marginRight: 20,
    //flexDirection: 'column',
    fontSize: 9,
    lineHeight: 1.3,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: "auto",
    //justifyContent: 'center'
  },
  row: {
    flexDirection: "row",
  },
  bold: {
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 7,
  },
  columns: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    //width: '50%',
    flexDirection: "column",
  },
  charts: {
    //width: '50%',
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chart: {
    width: 120,
  },
  scan: {
    maxWidth: 150,
    maxHeight: 70,
  },
  scanImg: {
    objectFit: "scale-down",
  },
  readings: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  readingKey: {
    marginLeft: 15,
    fontSize: 9,
    textAlign: "right",
    width: 60,
  },
  reading: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 8,
    textAlign: "center",
    width: 35,
  },
  cell: {
    margin: 0.5,
  },
  link: {
    marginLeft: 5,
  },
  certify: {
    fontFamily: "Merriweather",
    fontStyle: "italic",
    fontSize: 9,
  },
  approve: {
    flexDirection: "row",
    marginLeft: 140,
  },
  itemImages: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    //justifyContent: 'space-around',
    display: "flex",
  },
  itemImage: {
    maxWidth: 175,
    maxHeight: 120,
    padding: 8,
  },
  itemPic: {
    objectFit: "contain",
  },
  spacing: {
    flexGrow: 1,
    flexBasis: "auto",
    display: "flex",
  },
  finalSection: {
    position: "absolute",
  },
  final: {
    bottom: 0,
    left: 0,
  },
});

Object.defineProperty(String.prototype, "fixLink", {
  value: function () {
    var link = this;
    var start = link.search("/repair_work") + 1;
    return start
      ? link.substr(0, start) + link.substr(start).replace(/\//g, "%2F")
      : link;
  },
});

const Certificate = ({ item, itemId, itemReports, customer, issueDate, packageNumber }) => {
  const dates = _.map(
    _.pickBy(itemReports, (el) => el && el.date),
    (report) => report.date
  ).sort((a, b) => new Date(a) - new Date(b));
  const firstDate = dates[0];
  const lastDate = dates[dates.length - 1];
  const { visual, utt, rebuild, hydro, thread, mpi } = itemReports;
  const formatDate = date => date && date.slice(5) + "-" + date.slice(0, 4);
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={[styles.viewBox, styles.viewBoxOuter]} fixed>
          <View style={{ ...styles.viewBox, paddingBottom: 10 }} fixed></View>
        </View>
        <View style={styles.header} fixed>
          <View style={styles.line}></View>
          <Image fixed cache style={styles.logo} src={logo} />
          <View style={styles.contact}>
            <Text>1537 Coteau Rd.</Text>
            <Text>Houma, LA 70364</Text>
            <Text>PH: (985) 879-1002</Text>
            <Text>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                src="http://www.gulf-pro.com"
              >
                www.gulf-pro.com
              </Link>
            </Text>
          </View>
        </View>
        <View style={styles.titleRow}>
          <View style={styles.title}>
            <Text>
              {item.fail
              ? "Equipment Failure Report"
              : customer && customer === "GPS (C.O.S)"
                ? " Certificate of Service "
                : customer && customer === "C & D Wireline"
                ? "Annual Certificate of Service"
                : "Certificate of Conformance"}
            </Text>
          </View>
          {Boolean(issueDate) ? (
            <View style={styles.issueDate}>
              <Text>
                Issue Date: {formatDate(issueDate)}
              </Text>
            </View>
          ):null}
          {Boolean(packageNumber) ?
          (
            <View style={{ ...styles.packageNumber , top: issueDate ? -28 : -16 } }>
              <Text>
                Package: {`${packageNumber}`}
              </Text>
            </View>
          )
            : null
          }
        </View>
        <View style={styles.sectionContainer} wrap={false}>
          <View style={styles.section} fixed={false}>
            <View style={styles.sectionTitle}>
              <Text>Asset</Text>
            </View>
            <View style={styles.sectionBody}>
              <View style={styles.row}>
                <Text>Asset Number:</Text>
                <Text style={styles.bold}>{item.asset}</Text>
              </View>
              <View style={styles.row}>
                <Text>Description: </Text>
                <Text style={styles.bold}>{item.description}</Text>
              </View>
              {item.oem ? (
                <View style={styles.row}>
                  <Text>Manufacturer: </Text>
                  <Text style={styles.bold}>{item.oem}</Text>
                </View>
              ) : null}
              {item.serial ? (
                <View style={styles.row}>
                  <Text>Serial Number: </Text>
                  <Text style={styles.bold}>{item.serial}</Text>
                  {_.map(item.mtrs, (mtr) => (
                    <Text style={styles.link} key={mtr.url.fixLink()}>
                      <Link
                        href={mtr.url.fixLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </Link>
                    </Text>
                  ))}
                </View>
              ) : null}
              {item.serviceType ? (
                <View style={styles.row}>
                  <Text>Service Type: </Text>
                  <Text style={styles.bold}>{item.serviceType}</Text>
                </View>
              ) : null}
              {item.wp > 0 ? (
                <View style={styles.row}>
                  <Text>MAWP: </Text>
                  <Text style={styles.bold}>{item.wp} psi</Text>
                </View>
              ) : null}
              {item.tempRating ? (
                <View style={styles.row}>
                  <Text>Temperature Rating: </Text>
                  <Text style={styles.bold}>{item.tempRating}</Text>
                </View>
              ) : null}
            </View>
            {item.images && (
              <View style={styles.itemImages}>
                {_.map(item.images, (image) => {
                  return (
                    image.useOnReport && (
                      <Link
                        href={image.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={image.url}
                      >
                        <View style={styles.itemImage}>
                          <Image
                            src={image.reportUrl ? image.reportUrl : image.url}
                            style={styles.itemPic}
                          />
                        </View>
                      </Link>
                    )
                  );
                })}
              </View>
            )}
          </View>
        </View>
        {customer && customer === "Gulf-Pro Services" && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text>Date</Text>
              </View>
              <View style={styles.sectionBody}>
                <View style={styles.row}>
                  <Text>Certificate Date: </Text>
                  <Text style={styles.bold}>{moment(lastDate).format("MM-DD-YYYY")}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Valid Until: </Text>
                  <Text style={styles.bold}>
                    {moment(firstDate).add(5, "y").format("MM-DD-YYYY")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        {visual && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text>Visual Inspection</Text>
              </View>
              <View style={styles.sectionBody}>
                <View style={styles.row}>
                  <Text>Inspector:</Text>
                  <Text style={styles.bold}>{visual.inspector}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Date: </Text>
                  <Text style={styles.bold}>{formatDate(visual.date)}</Text>
                </View>
                <View style={styles.row}>
                  <Text>
                    Report: <Text style={styles.bold}>{visual.report}</Text>
                  </Text>
                  {_.map(visual.reports, (report) => (
                    <Text style={styles.link} key={report.url.fixLink()}>
                      <Link
                        href={report.url.fixLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </Link>
                    </Text>
                  ))}
                </View>
                <View style={styles.row}>
                  <Text>Result:</Text>
                  <Text style={{ ...styles.bold, color: "#43a047" }}>
                    {_.some(visual.items, (item) => item.visualIndication)
                      ? "PASS*"
                      : "PASS"}
                  </Text>
                </View>
                {item.indicationPictures && (
                  <View style={styles.itemImages}>
                    {_.map(item.indicationPictures, (image) => {
                      return (
                        image.useOnReport && (
                          <Link
                            href={image.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={image.url}
                          >
                            <View style={styles.itemImage}>
                              <Image
                                src={
                                  image.reportUrl ? image.reportUrl : image.url
                                }
                                style={styles.itemPic}
                              />
                            </View>
                          </Link>
                        )
                      );
                    })}
                  </View>
                )}
                {_.some(visual.items, (item) => item.visualIndication) ? (
                  <View style={styles.row}>
                    <Text style={{ fontSize: 8 }}>
                      * Indication noted on visual report
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        )}
        {rebuild && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text>Rebuild</Text>
              </View>
              <View style={[styles.sectionBody, styles.columns]}>
                <View style={styles.column}>
                  <View style={styles.row}>
                    <Text>Rebuild Tech: </Text>
                    <Text style={styles.bold}>{rebuild.rebuilder}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>Date: </Text>
                    <Text style={styles.bold}>{formatDate(rebuild.date)}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>
                      Report: <Text style={styles.bold}>{rebuild.report}</Text>
                    </Text>
                    {_.map(rebuild.reports, (report) => (
                      <Text style={styles.link} key={report.url}>
                        (
                        <Link
                          href={report.url.fixLink()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          link
                        </Link>
                        )
                      </Text>
                    ))}
                  </View>
                  <View style={styles.row}>
                    <Text>Result:</Text>
                    <Text style={{ ...styles.bold, color: "#43a047" }}>
                      PASS
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
        {utt && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section} wrap={false}>
              <View style={styles.sectionTitle}>
                <Text>Ultrasonic Thickness Inspection</Text>
              </View>
              <View style={[styles.sectionBody, styles.columns]}>
                <View style={styles.column}>
                  <View style={styles.row}>
                    <Text>Inspector:</Text>
                    <Text style={styles.bold}>{utt.inspector}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>Date: </Text>
                    <Text style={styles.bold}>{formatDate(utt.date)}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text>Report: </Text>
                    <Text style={styles.bold}>{utt.report}</Text>
                    {_.map(utt.reports, (report) => (
                      <Text style={styles.link} key={report.url}>
                        (
                        <Link
                          href={report.url.fixLink()}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          link
                        </Link>
                        )
                      </Text>
                    ))}
                  </View>
                  <View style={styles.row}>
                    <Text>Result:</Text>
                    <Text style={{ ...styles.bold, color: "#43a047" }}>
                      PASS
                    </Text>
                  </View>
                </View>
                <View style={styles.column}>
                  <View style={styles.scan}>
                    <Image
                      src={utt.items[itemId].scanUrl}
                      style={styles.scanImg}
                    />
                  </View>
                </View>
                <View style={styles.readings}>
                  <View style={styles.readingKey}>
                    <Text>Reading:</Text>
                    <Text>Value: </Text>
                    <Text>Min Wall:</Text>
                  </View>
                  {_.map(utt.items[itemId].readings, (reading, index) => (
                    <View style={styles.reading} key={index + itemId}>
                      <Text style={styles.cell}>{`${index + 1}`}</Text>
                      <Text style={styles.cell}>{reading}</Text>
                      <Text style={styles.cell}>
                        {item.mins ? item.mins[index] : ""}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
        )}
        {thread && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text>Dimensional Inspection</Text>
              </View>
              <View style={styles.sectionBody}>
                <View style={styles.row}>
                  <Text>Inspector:</Text>
                  <Text style={styles.bold}>{thread.inspector}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Date:</Text>
                  <Text style={styles.bold}>{formatDate(thread.date)}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Report: </Text>
                  <Text style={styles.bold}>{thread.report}</Text>
                  {_.map(thread.reports, (report) => (
                    <Text style={styles.link} key={report.url}>
                      (
                      <Link
                        href={report.url.fixLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </Link>
                      )
                    </Text>
                  ))}
                </View>
                <View style={styles.row}>
                  <Text>Result:</Text>
                  <Text style={{ ...styles.bold, color: "#43a047" }}>PASS</Text>
                </View>
              </View>
            </View>
          </View>
        )}
        {mpi && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section} wrap={false}>
              <View style={styles.sectionTitle}>
                <Text>Nondestructive Examination</Text>
              </View>
              <View style={styles.sectionBody}>
                <View style={styles.row}>
                  <Text>Inspector:</Text>
                  <Text style={styles.bold}>{mpi.inspector}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Date:</Text>
                  <Text style={styles.bold}>{formatDate(mpi.date)}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Report: </Text>
                  <Text style={styles.bold}>{mpi.report}</Text>
                  {_.map(mpi.reports, (report) => (
                    <Text style={styles.link} key={report.url}>
                      (
                      <Link
                        href={report.url.fixLink()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </Link>
                      )
                    </Text>
                  ))}
                </View>
                <View style={styles.row}>
                  <Text>Result:</Text>
                  <Text style={{ ...styles.bold, color: "#43a047" }}>PASS</Text>
                </View>
              </View>
            </View>
          </View>
        )}
        {hydro &&
          _.map(hydro, (hydroChart, hydroChartId) => (
            <View
              style={styles.sectionContainer}
              wrap={false}
              key={hydroChartId}
            >
              <View style={styles.section} wrap={false}>
                <View style={styles.sectionTitle}>
                  <Text>Hydrostatic Pressure Test</Text>
                </View>
                <View style={[styles.sectionBody, styles.columns]}>
                  <View style={styles.column}>
                    <View style={styles.row}>
                      <Text>Tester:</Text>
                      <Text style={styles.bold}>{hydroChart.tester}</Text>
                    </View>
                    <View style={styles.row}>
                      <Text>Date: </Text>
                      <Text style={styles.bold}>
                        {formatDate(hydroChart.date) ||
                          moment(hydroChart.timestamp).format("MM-DD-YYYY")}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text>Chart: </Text>
                      <Text style={styles.bold}>
                        {hydroChart.report ? (
                          <Text style={styles.link}>
                            
                            <Link
                              href={hydroChart.report}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {hydroChart.chart}
                            </Link>
                            
                          </Text>
                        ) : (
                          hydroChart.chart
                        )}
                      </Text>
                      {_.map(hydroChart.reports, (report) => (
                        <Text style={styles.link} key={report.url}>
                          (
                          <Link
                            href={report.url.fixLink()}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            link
                          </Link>
                          )
                        </Text>
                      ))}
                    </View>
                    <View style={styles.row}>
                      <Text>Result:</Text>
                      <Text style={{ ...styles.bold, color: "#43a047" }}>
                        {item.fail ? `FAIL: ${item.fail}` : "PASS"}
                      </Text>
                    </View>
                  </View>
                  {hydroChart.holds && (
                    <View style={styles.charts}>
                      {_.map(hydroChart.holds, (hold, holdId) => {
                        return (
                          <View key={holdId} style={styles.chart}>
                            <Text style={{ textAlign: "center", fontSize: 8 }}>
                              {hold.intervals && hold.intervals[0].name}
                            </Text>
                            <Image src={hold.image} />
                          </View>
                        );
                      })}
                    </View>
                  )}
                </View>
              </View>
            </View>
          ))}
        <View style={styles.spacing} />
        <View style={styles.sectionContainer}>
          <View style={styles.section} wrap={false}>
            <View style={styles.final}>
              <View style={styles.sectionTitle}>
                <Text>Result</Text>
              </View>
              <View style={{ ...styles.sectionBody }}>
                <Text style={styles.certify}>
                  {item.fail
                  ? `Reason: ${item.fail}`
                  : customer && customer === "Halliburton"
                    ? " This is to certify that all item(s) supplied under the reference order were repaired and/or recertified, inspected," +
                      " tested and shipped in compliance with Gulf-Pro Services’ Quality. All accepted items meet or exceed the specific" +
                      " customer requirement: Halliburton SMEEM SAP No. 100011920 Rev OK. These requirements may include the customer" +
                      " and/or OEM specifications, as well as GPS internal operating procedures."
                    : "We hereby certify that all item(s) supplied under the reference order were repaired and/or recertified," +
                      " inspected, tested and shipped in compliance with Gulf-Pro Services’ Quality System. All accepted items meet" +
                      " or exceed the specific customer requirements to which they were ordered. These requirements may include the" +
                      " customer and/or OEM specifications, as well as GPS internal operating procedures."}
                </Text>
                <Text> </Text>
                <Text style={styles.bold}>
                  Certified by: Dustin Stein – Quality Director
                </Text>
                <View style={styles.approve}>
                  <Image src={signature} style={styles.chart} />
                  <Image src={item.fail? fail : pass} style={styles.chart} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const App = (props) => {
  const {
    singleCOC,
    items,
    utt,
    rebuild,
    hydro,
    mpi,
    thread,
    uploadCOC,
    markComplete,
    jobId,
    updateAttachments,
    customer,
    removeCocZip,
    makingCert,
    removeCOCReport,
    setMenu,
    packageNumber,
  } = props;
  // const [working, setWorking] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [includeFailures, setIncludeFailures] = React.useState(false);
  const [certDate, setCertDate] = React.useState(() => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  });
  const [reissueExisting, setReissueExisting] = React.useState(false);
  
  const [includeDateonCert, setIncludeDateonCert] = React.useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const generatePdfDocument = (item, itemId, itemReports) => {
    const blob = pdf(
      <Certificate
        title="My PDF"
        item={item}
        itemId={itemId}
        itemReports={itemReports}
        customer={customer}
        issueDate={includeDateonCert && certDate}
        packageNumber={packageNumber}
      />
    ).toBlob();
    return blob;
  };

  const hasUTT = (itemId) => {
    const result = _.find(
      utt,
      (report) => report.items[itemId] && report.items[itemId].readings
    );
    return _.size(result) > 0 && result;
  };

  const hasVisual = (itemId) => {
    const result = _.find(
      utt,
      (report) => report.items[itemId] && report.items[itemId].visual
    );
    return _.size(result) > 0 && result;
  };

  const hasRb = (itemId) => {
    const result = _.find(rebuild, (report) => report.items[itemId]);
    return _.size(result) > 0 && result;
  };

  const hasMPI = (itemId) => {
    const result = _.find(mpi, (report) => report.items[itemId]);
    return _.size(result) > 0 && result;
  };

  const hasThread = (itemId) => {
    const result = _.find(thread, (report) => report.items[itemId]);
    return _.size(result) > 0 && result;
  };

  const hasHydro = (itemId) => {
    const searchAsset = items && items[itemId] && items[itemId].asset;
    const result = _(hydro)
      .pickBy((report) => {
        const isItemId = Boolean(
          report.items &&
            report.items[itemId] &&
            !report.items[itemId].associated
        );
        const hasAssetNumber = Boolean(
          _.find(report.items, (chartItem) => chartItem.asset === searchAsset)
        );
        const include =
          report.includeOnReport && report.includeOnReport[itemId];
        return Boolean(include && (isItemId || hasAssetNumber));
      })

      // .map(info => {
      //   return Boolean((info && !info.associated) || (info && info.asset === searchAsset))

      // })
      .value();
    return _.size(result) > 0 && result;
  };

  const isComplete = (itemId, attr) => {
    switch (attr) {
      case "thread":
        return hasThread(itemId);
      case "mpi":
        return hasMPI(itemId);
      case "hydro":
        return hasHydro(itemId);
      case "rebuild":
        return hasRb(itemId);
      case "visual":
        return hasVisual(itemId);
      case "utt":
        return hasUTT(itemId);
      default:
        return;
    }
  };

  const reports = (item, itemId) => {
    let result = {};
    ["visual", "rebuild", "utt", "thread", "hydro", "mpi"].map((attr) => {
      return (result[attr] = item[attr] 
      ? isComplete(itemId, attr) 
      : null);
    });
    return result;
  };

  const uploadAsync = ({ jobId, itemId, blob, fileName }) => {
    return new Promise((resolve, reject) => {
      uploadCOC(jobId, itemId, blob, fileName, certDate, resolve);
    });
  };
  const makeCerts = async () => {
    //setWorking(true);
    removeCocZip(jobId);
    const create = async (item, itemId) => {
        makingCert("start", itemId);
        const itemReports = reports(item, itemId);
        if (_.includes(itemReports, false) || !itemReports) {
          if (!item.fail || (item.fail && !includeFailures)) {
          removeCOCReport(jobId, itemId, () => {
            enqueueSnackbar(
              `COC for ${items[itemId] && items[itemId].asset} removed`,
              { variant: "warning" }
            );
          });
          return makingCert("stop", itemId);
          }
        }
        const parts = _(
          itemReports.rebuild &&
            itemReports.rebuild.parts &&
            itemReports.rebuild.parts[itemId]
        )
          .map("fullPath")
          .value();
        const hydroReports = _(itemReports.hydro)
          .pickBy("reports")
          .map("reports")
          .flatten()
          .filter("fullPath")
          .map("fullPath")
          .value();

        const linkHydroReports = _(itemReports.hydro)
          .pickBy("report")
          .map((reportUrl) => {
            const url = new URL(reportUrl.report);
            const path = url.pathname;
            const remove = "/v0/b/custom-app-project-test.appspot.com/o/";
            const removeLength = remove.length;
            const filePath = path
              .substr(removeLength, path.length)
              .replace(/%2F/gi, "/");
            return filePath;
          })
          .value();
        const attachments = _(itemReports)
          .map("reports")
          .flatten()
          .filter("fullPath")
          .map("fullPath")
          .uniq()
          .value();
        const mtrs = _.filter(
          _.map(
            item.mtrs,
            (mtr) => mtr.filePath && `repair_work/mtrs/${mtr.filePath}`
          )
        );
        updateAttachments(itemId, jobId, [
          ...attachments,
          ...parts,
          ...hydroReports,
          ...linkHydroReports,
          ...mtrs,
        ]);
        await register.catch(() => makingCert("error", itemId));
        const res = async () => {
          const blob = await generatePdfDocument(item, itemId, itemReports);
          await uploadAsync({
            jobId: item.jobId,
            itemId,
            blob,
            fileName: `${item.asset.replace("/", "-")}.pdf`,
          });
          return;
        };
        await res();
      }
    await Promise.all(
      _.map(items, async (item, itemId) => {
        // if (!!item.fail) return null;
        if(singleCOC && itemId !== singleCOC) return null;
        if (reissueExisting) {
          await create(item, itemId);
          return 
        } else if (!reissueExisting && !item.cocs) {
          await create(item, itemId);
          return 
        } else {
          return null
        }
      })
    ).then(() => markComplete(jobId));
  };

  return (
    <>
      <Form
        include={includeDateonCert}
        date={certDate}
        setDate={setCertDate}
        setInclude={setIncludeDateonCert}
        open={open}
        handleClose={() => {
          setOpen((prev) => !prev);
          setMenu(null)
        }}
        makeCerts={makeCerts}
        reissueExisting={reissueExisting}
        
        includeFailures={includeFailures}
        setIncludeFailures= {setIncludeFailures}
        setReissueExisting={setReissueExisting}
      />
      <div
        onClick={() => {
          setOpen(true);
          !singleCOC && setMenu(null);
        }}
      >
        {singleCOC ? 'Create Item COC' : 'Make Certs'}
        {/* <Working open={working} /> */}
      </div>
    </>
  );
};

const Form = ({
  include,
  date,
  setDate,
  setInclude,
  reissueExisting,
  setReissueExisting,
  open,
  handleClose,
  makeCerts,
  includeFailures,
  setIncludeFailures
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Include Issue Date on Certificate?</DialogTitle>
      <form className={classes.root}>
        <TextField
          label="Issue Date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
          }}
          margin="normal"
          variant="outlined"
          type="date"
          disabled={!include}
        />
        <Box sx={{alingItems: 'left', display: 'grid'}}>
        <FormControlLabel
          control={
            <Switch
              checked={include}
              onChange={() =>
                setInclude((p) => {
                  return !p;
                })
              }
              color="primary"
            />
          }
          label="Include an Issue Date on COC"
        />
        <FormControlLabel
          control={
            <Switch
              checked={includeFailures}
              onChange={() =>
                setIncludeFailures((p) => {
                  return !p;
                })
              }
              color="primary"
            />
          }
          label="Include Failed Items"
        />
        <FormControlLabel
          control={
            <Switch
              checked={reissueExisting}
              onChange={() =>
                setReissueExisting((p) => {
                  return !p;
                })
              }
              color="primary"
            />
          }
          label="Recreate existing COCs"
        />
        </Box>
      </form>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            makeCerts();
            handleClose();
          }}
          color="primary"
        >
          Create COCs
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ items, utt, rebuild, hydro, mpi, thread }) {
  return {
    items,
    utt,
    rebuild,
    hydro,
    mpi,
    thread,
  };
}

export default connect(mapStateToProps, actions)(App);
